export const getPurchaseRequests = (status) => {
  const statusColors = {
    CANCELED: "red",
    PENDING: "green",
    DRAFT: "indigo",
    FINISHED: "blue",
    // "En preparation": "yellow",

    // "En livraison": "orange",
    // Pret: "blue",
    // Clôturé: "cyan",
  };

  return statusColors[status] || "grape"; // Default color if status doesn't match
};
