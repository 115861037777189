import React from "react";
import ClientInfo from "pages/SheetOfClientPage/ClientInfo";
import { isEmpty } from "./isEmpty";

const ClientComp = ({ data }) => {
  if (isEmpty(data)) return isEmpty(data);
  return <ClientInfo data={data} toClientSheet />;
};

export default ClientComp;
