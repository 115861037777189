import { Modal, ModalBody, ModalHeader } from "reactstrap";
import React, { Fragment, useState } from "react";
import { IconTrash } from "@tabler/icons-react";
import { ActionIcon, Button } from "@mantine/core";

import { Button as PrimeBtn } from "primereact/button";

import {
  addAlert,
  areYouSureAlert,
  changedAlert,
} from "Components/Alerts/Alerts";
import AddItem from "Components/AddItemWithZod";
import Translate from "Components/Translate";

import { toggleRefresh } from "../../redux/searchLink";
import { store } from "../../redux/store";

import axiosClient from "Services/axiosClient";
import patchItem from "Services/patchItem";
import errorMsg from "Services/errorMsg";

import { CANCEL, CONFIRM } from "Constant";

export const ConfirmEstimate = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="orange"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{CONFIRM}</Translate>
    </Button>
  );
};

export const CancelEstimate = ({ url, disabled, row }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const cancelTitle = `Annuler le devis N° ${row.estimate_number}`;

  const cancelFn = async (
    new_data,
    URL_CREATE,
    changeLoadingState,
    success
  ) => {
    console.log(new_data);
    await axiosClient
      .patch(URL_CREATE, new_data)
      .then((res) => {
        addAlert();
        success();
      })
      .catch((error) => {
        errorMsg(error);
      });
    changeLoadingState(false);
  };

  const CancelBtn = () => (
    <PrimeBtn
      onClick={() => setIsOpen(false)}
      severity="secondary"
      type="button"
    >
      <Translate>{CANCEL}</Translate>
    </PrimeBtn>
  );
  return (
    <Fragment>
      <ActionIcon
        variant="light"
        color="red"
        aria-label="Settings"
        onClick={() => toggle()}
        disabled={disabled}
      >
        <IconTrash />
      </ActionIcon>

      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        centered
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={toggle}>{cancelTitle}</ModalHeader>
        <ModalBody>
          <AddItem
            cancelBtn={<CancelBtn />}
            customFn={cancelFn}
            addURL={url}
            items={[
              {
                title: "Reason",
                placeholder: "Reason",
                name: "reason",
                inputForm: "SimpleInput",
              },
            ]}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};
