//import { getCodeLabel } from "../../helper/getOptions";

import ActiveBtn from "../../../Components/DataTableBtns/ActiveBtn";
import CheckBox from "../../../Components/DataTableBtns/CheckBox";
import EditBtn from "../../../Components/DataTableBtns/EditBtn";
import SelectAll from "../../../Components/DataTableBtns/SelectAll";
import { getTitleCodeLabel } from "../../../helper/getOptions";

// Urls
export const API = {
  list: "/back/obdAdapters",
  choice: "/back/obdAdapters/choices",
  Serial: "/back/obdAdapters/serials",
  create: "/back/obdAdapters",
  get: "/back/obdAdapter/",
  edit: "/back/obdAdapter/",
  patch: "/back/obdAdapter/",
  import: "/back/obdAdapters",
};

// Columns
export const COLUMNS = [
  {
    name: <SelectAll>generate all</SelectAll>,
    cell: (row) => <CheckBox rowCode={row.code} key={row.code} />,
    button: "true",
  },
  {
    name: "Number",
    selector: "serial_number",
  },
  {
    name: "Status",
    selector: "status",
  },
  { name: "Created at", selector: "created_at" },
  { name: "Updated At", selector: "updated_at" },
  {
    name: "Active",
    cell: (row) => (
      <ActiveBtn patch={API.patch + row.code} state={row.active} />
    ),
    button: "true",
  },
  {
    name: "Edit",
    cell: (row) => <EditBtn read={API.get + row.code} extra={" "} />,
    button: "true",
  },
];

export const SEARCH_ITEMS = [
  {
    name: "status",
    title: "status",
    inputForm: "SelectInput",
    options: [
      {
        value: "sold",
        label: "sold",
        name: "status",
      },
      {
        value: "in_stock",
        label: "in_stock",
        name: "status",
      },
      {
        value: "in_preparation",
        label: "in_preparation",
        name: "status",
      },
      {
        value: "under_examination",
        label: "under_examination",
        name: "status",
      },
      {
        value: "",
        label: "all",
        name: "status",
      },
    ],
  },
];

// Form
export const CREATE_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/obd_adapter",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    placeholder: "amount",
    name: "amount",
    inputForm: "TitleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Model",
    name: "model",
    inputForm: "SelectApiInput",
    optionsApi: "/back/models/choices/obd_adapter",
    getOptions: getTitleCodeLabel,
  },
  {
    title: "Amount",
    placeholder: "amount",
    name: "amount",
    inputForm: "TitleInput",
  },
];
