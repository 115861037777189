import React from "react";

import { useDispatch } from "react-redux";
import { changeModalType, changeTitle } from "../../redux/modal";
import { changeCurrentRow } from "../../redux/currentRow";

import { Menu, rem } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import Translate from "Components/Translate";
import { EDIT } from "Constant";

const SettingsEditBtn = ({ read, edit, extra }) => {
  console.log(extra);

  const dispatch = useDispatch();

  const handleEdit = () => {
    const selectedRow = {
      getLink: read || edit,
      putLink: edit || read,
    };
    dispatch(changeModalType("edit"));
    dispatch(changeTitle(extra));
    dispatch(changeCurrentRow(selectedRow));
  };

  return (
    <Menu.Item
      leftSection={<IconEdit style={{ width: rem(14), height: rem(14) }} />}
      onClick={handleEdit}
    >
      <Translate>{EDIT}</Translate>
    </Menu.Item>
  );
};

export default SettingsEditBtn;
