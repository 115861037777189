import { ShowErrorMsg, addAlert } from "Components/Alerts/Alerts";
import { FILL_OUT_THIS_FIELDS } from "Constant";
import axiosClient from "Services/axiosClient";

import Swal from "sweetalert2";

const onSubmit = async (newData, url, changeLoadingState, setErrors, edit) => {
  console.log(newData);

  const errors = [];
  let text = "";

  if (!newData.client) {
    errors.push("client");
    setErrors((prevState) => ({ ...prevState, client: true }));
  }
  if (!newData.taxi) {
    errors.push("taxi");
    setErrors((prevState) => ({ ...prevState, taxi: true }));
  }

  if (newData.remainingAmount < 0) {
    errors.push("Remaining amount > 0");
    setErrors((prevState) => ({ ...prevState, remainingAmount: true }));
  }

  errors.forEach((element) => {
    text = text !== "" ? text + ` , ${element}` : text + ` ${element}`;
  });

  if (errors.length) {
    changeLoadingState(false);
    return Swal.fire({
      title: FILL_OUT_THIS_FIELDS,
      text: text,
      icon: "warning",
    });
  }

  edit
    ? await axiosClient
        .put(url, newData)
        .then((res) => {
          addAlert();

          const href = `/estimate/${res.data.results.data.object}`;
          setTimeout(() => {
            window.location.href = href;
          }, 500);
        })
        .catch((error) => {
          ShowErrorMsg(error);
        })
    : await axiosClient
        .post(url, newData)
        .then((res) => {
          addAlert();

          const href = `/estimate/${res.data.results.data.object}`;
          setTimeout(() => {
            window.location.href = href;
          }, 500);
        })
        .catch((error) => {
          ShowErrorMsg(error);
        });

  if (changeLoadingState) changeLoadingState(false);
};

export default onSubmit;
