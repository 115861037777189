import React, { Fragment } from "react";
import { Button } from "@mantine/core";

import patchItem from "Services/patchItem";

import OpenModalBtn from "Components/ModalApp/OpenModalBtn";
import Translate from "Components/Translate";

import { CONFIRM, CREATE_DELIVERY_FORM, PREPARE, READY } from "Constant";

export const ConfirmPaymentBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="green"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{CONFIRM}</Translate>
    </Button>
  );
};

export const CreateDeliveryFormBtn = ({ disabled, data }) => {
  return (
    <Fragment>
      <OpenModalBtn
        type={"createCreateDelivaryForm"}
        title={data.po_number}
        data={data}
      >
        <Button variant="filled" color="orange" size="xs" disabled={disabled}>
          <Translate>{CREATE_DELIVERY_FORM}</Translate>
        </Button>
      </OpenModalBtn>
    </Fragment>
  );
};

export const PrepareDeliveryFormBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="orange"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{PREPARE}</Translate>
    </Button>
  );
};

export const ReadyDeliveryFormBtn = ({ url, disabled }) => {
  return (
    <Button
      variant="filled"
      color="orange"
      size="xs"
      onClick={() => patchItem(url)}
      disabled={disabled}
    >
      <Translate>{READY}</Translate>
    </Button>
  );
};
