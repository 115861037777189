import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { Button } from "primereact/button";

import { Divider, Space } from "@mantine/core";

import Translate from "Components/Translate";
import Breadcrumbs from "Components/Breadcrumbs";
import SelectApiInput from "Components/Inputs/SelectApiInput";

import {
  CREATE_ESTIMATE,
  EDIT_ESTIMATE,
  PRODUCTS,
  SERVICES,
  SUBMIT,
  TYPE,
} from "Constant";

import { refreshEstimate } from "../../redux/estimateSlicer";

import { AddProductBtn, AddServiceBtn } from "./EstimatesBtns";
import DisplayClientDetails from "./DisplayClientDetails";
import TotalResultArray from "./TotalResultArray";
import EstimateDetails from "./EstimateDetails";
import onSubmit from "./onSubmitEstimate";
import OneProduct from "./OneProduct";
import OneService from "./OneService";

import {
  clientInp,
  estiamteTypeOption,
  estimateType,
  initError,
  initEstimateD,
} from "./data";

import { calculatePrice } from "helper/estimateFx";
import SelectInput from "Components/Inputs/SelectInput";

const CreateEstimatePage = ({ oldData, oldClient, oldEstimateD, url }) => {
  const dispatch = useDispatch();

  const breadCrumbsProps = oldData
    ? [`${EDIT_ESTIMATE} N°${oldData.estimateNumber}`]
    : [CREATE_ESTIMATE];

  const [client, setClient] = useState(oldClient ?? "");
  const [isLoading, setIsLoading] = useState(false);
  const [estimateD, setEstimateD] = useState(oldEstimateD ?? initEstimateD);
  const [errors, setErrors] = useState(initError);

  const { products, services } = useSelector((state) => state.estimate);

  const res =
    estimateD.type === "product"
      ? calculatePrice(
          products,
          [],
          estimateD.timescale,
          estimateD.deposit,
          estimateD.estimate_coefficient
        )
      : calculatePrice(
          [],
          services,
          estimateD.timescale,
          estimateD.deposit,
          estimateD.estimate_coefficient
        );

  useEffect(() => {
    return () => {
      dispatch(refreshEstimate());
    };
  }, [dispatch]);

  const remainingAmount = (res.priceVat - estimateD.deposit).toFixed(1);

  const getSendData = () => {
    const sendProd = products
      .map((elem) => {
        if (elem.category && elem.model) {
          return {
            category: elem.category,
            model: elem.model,
            description: elem.description,
            price: elem.price,
            quantity: elem.quantity,
            vat: elem.vat,
            discount: elem.discount,
          };
        }
        return null; // Return null for items that don't meet the condition
      })
      .filter((item) => item !== null); // Filter out null values

    const sendServ = services
      .map((elem) => {
        if (elem.service) {
          return {
            service: elem.service,
            description: elem.description,
            price: elem.price,
            quantity: elem.quantity,
            vat: elem.vat,
            discount: elem.discount,
          };
        }
        return null; // Return null for items that don't meet the condition
      })
      .filter((item) => item !== null); // Filter out null values

    return {
      client: client,
      taxi: estimateD.taxi,
      price: res.price,
      vatAmount: res.vatAmount,
      priceVat: res.priceVat,
      deposit: estimateD.deposit,
      timescale: estimateD.timescale,
      remainingAmount: remainingAmount,
      products: estimateD.type === "product" ? sendProd : [],
      services: estimateD.type === "service" ? sendServ : [],
    };
  };
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <Divider my="lg" />

          <Row>
            <SelectApiInput
              register={(e) => {
                setErrors((prevState) => ({ ...prevState, client: false }));
                setClient(e.value);
              }}
              inputName={clientInp}
              oldValue={oldClient}
            />
            {errors.client && (
              <span className="text-danger">Client should not be empty </span>
            )}

            <SelectInput
              register={(e) => {
                setEstimateD((pervState) => ({
                  ...pervState,
                  type: e.value,
                }));
              }}
              inputName={estimateType}
              oldValue={estimateD.type}
            />
          </Row>

          {client && <DisplayClientDetails clientCode={client} />}

          <Divider my="lg" />

          <EstimateDetails
            client={client}
            inputs={estimateD}
            register={setEstimateD}
            errors={errors}
            setErrors={setErrors}
            remainingAmount={remainingAmount}
          />

          <Divider my="lg" />

          {estimateD.type === "service" ? (
            <Fragment key="service">
              {services.map((elem, i) => (
                <Fragment key={elem.id}>
                  <OneService
                    index={i}
                    oldData={oldData ? services[i] : null}
                  />
                </Fragment>
              ))}
              <AddServiceBtn />
            </Fragment>
          ) : (
            <Fragment key="product">
              {products.map((elem, i) => (
                <Fragment key={elem.id}>
                  <OneProduct
                    index={i}
                    oldData={oldData ? products[i] : null}
                  />
                </Fragment>
              ))}
              <AddProductBtn />
            </Fragment>
          )}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <TotalResultArray result={res} />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <Button
                    type="submit"
                    label={<Translate>{SUBMIT}</Translate>}
                    severity="success"
                    loading={isLoading}
                    onClick={() => {
                      setIsLoading(true);

                      onSubmit(
                        getSendData(),
                        url ? url : "/back/estimate",
                        setIsLoading,
                        setErrors,
                        url ? true : false
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CreateEstimatePage;
