import ActiveBtn from "../../../Components/DataTableBtns/ActiveBtn";
import CheckBox from "../../../Components/DataTableBtns/CheckBox";
import EditBtn from "../../../Components/DataTableBtns/EditBtn";
import { activeObj } from "../../../Constant/objs";

// Urls
export const API = {
  list: "/back/printers",
  choice: "/back/printers/choices",
  Serial: "/back/printers/serials",
  create: "/back/printer",
  get: "/back/printer/",
  edit: "/back/printer/",
  patch: "/back/printer/",
  import: "/back/printers",
};

// Columns
export const COLUMNS = [
  {
    name: "QR Code",
    cell: (row) => <CheckBox rowCode={row.code} key={row.code} />,
    button: "true",
  },
  {
    name: "Number",
    selector: "serial_number",
  },
  {
    name: "Status",
    selector: "status",
  },
  { name: "Created at", selector: "created_at" },
  { name: "Updated At", selector: "updated_at" },
  {
    name: "Active",
    cell: (row) => (
      <ActiveBtn patch={API.patch + row.code} state={row.active} />
    ),
    button: "true",
  },
  {
    name: "Edit",
    cell: (row) => (
      <EditBtn read={API.get + row.code} extra={row.serial_number} />
    ),
    button: "true",
  },
];

export const SEARCH_ITEMS = [activeObj];

// Form
export const CREATE_ITEMS = [
  {
    title: "Amount",
    placeholder: "amount",
    name: "amount",
    inputForm: "TitleInput",
  },
];

export const EDIT_ITEMS = [
  {
    title: "Amount",
    placeholder: "amount",
    name: "amount",
    inputForm: "TitleInput",
  },
];
