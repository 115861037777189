import { Group } from "@mantine/core";
import DownloadPDF from "Components/MantineDataTableBtns/DownloadPDF";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";

import {
  CLIENT,
  CREATED_AT,
  PRICE_WITH_VAT,
  PRODUCTS,
  SERVICES,
  TAXI,
} from "Constant";
import { createdAt } from "Constant/objs";

// API
export const API = {
  list: "/back/bills",
  get: "/back/bill",
  create: "/back/bill/",
};

// Columns
export const COLUMNS = [
  {
    header: CLIENT,
    accessorFn: (originalRow) => originalRow.complete_name,
    id: "complete_name",
    size: 300,

    Cell: ({ row }) => {
      const { complete_name, code_client } = row.original;
      const path = `/client/${code_client}`;

      return <ReadSheetBtn read={path} title={complete_name} />;
    },
  },
  {
    header: TAXI,
    accessorFn: (originalRow) => originalRow.taxi,
    id: "taxi",
    size: 300,

    Cell: ({ row }) => {
      const { taxi, code_taxi } = row.original;
      const path = `/taxi/${code_taxi}`;

      return <ReadSheetBtn read={path} title={taxi} />;
    },
  },

  { name: PRODUCTS, selector: "count_products" },
  { name: SERVICES, selector: "count_services" },

  {
    name: PRICE_WITH_VAT,
    selector: "price_vat",
  },
  createdAt,

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 80,
    Cell: ({ row }) => {
      const { code, to_confirm = true, to_cancel = true } = row.original;
      const urlConfirmPayment = `/back/bill/${code}/confirm-payment`;
      const urlCancel = `/back/bill/${code}`;

      return (
        <Group gap="xs">
          {/* <ConfirmBtn url={urlConfirmPayment} disabled={!to_confirm} />
          <CancelBtn url={urlCancel} disabled={!to_cancel} /> */}
          <DownloadPDF read={`/bill/${code}`} />
        </Group>
      );
    },
  },
];
