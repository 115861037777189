import { Badge, Group } from "@mantine/core";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";
import ReadSheetBtn from "Components/MantineDataTableBtns/ReadSheetBtn";
import {
  BRAND,
  CIRCULATION_DATE,
  CLIENT,
  CREATED_AT,
  DRIVERS,
  FIRMWARE,
  LICENCE,
  LICENCE_EXPIRE_AT,
  LICENCE_GOT_AT,
  MODEL,
  REGISTRATION_NUMBER,
  SERIAL_NUMBER,
  SIM,
  STATUS,
  TAXI,
  TAXIMETER,
} from "Constant";
import { createdAt, updatedAt } from "Constant/objs";
import { formatDate } from "helper/dateFormat";

export const API = {
  list: "/back/taximeters?index=-1&complete=true",
};

export const COLUMNS = [
  {
    header: CLIENT,
    size: 300,
    accessorFn: (originalRow) => originalRow.client,
    id: "client",

    Cell: ({ row }) => {
      const { client, code_client } = row.original;
      const path = `/client/${code_client}`;

      return <ReadSheetBtn read={path} title={client} />;
    },
  },
  {
    header: TAXI,
    size: 300,
    accessorFn: (originalRow) => originalRow.taxi,
    id: "taxi",

    Cell: ({ row }) => {
      const { taxi, code_taxi } = row.original;
      const path = `/taxi/${code_taxi}`;

      return <ReadSheetBtn read={path} title={taxi} />;
    },
  },

  {
    name: FIRMWARE,
    selector: "firmware",
  },
  {
    name: SIM,
    selector: "sim_phone",
  },

  {
    name: SERIAL_NUMBER,
    selector: "serial_number",
  },
  {
    accessorFn: (originalRow) => originalRow.status,
    id: "status",
    header: STATUS,

    filterFn: "equals",
    filterVariant: "select",
    mantineFilterSelectProps: {
      data: [
        { value: "En préparation", label: "En préparation" },
        { value: "Vendu", label: "Vendu" },
      ],
    },

    Cell: ({ row }) => {
      const { status } = row.original;
      let color = "gray";

      if (status === "Annulé") color = "red";
      if (status === "Crée") color = "indigo";
      if (status === "Confirmé") color = "teal";

      return (
        <Badge color={color} size="lg">
          {status}
        </Badge>
      );
    },
  },
  {
    accessorFn: (originalRow) => originalRow.created_at,
    id: "created_at",
    // filterVariant: "date-range",

    header: CREATED_AT,
    Cell: ({ row }) => {
      const { created_at } = row.original;
      return formatDate(created_at);
    },
  },
  updatedAt,
  {
    id: "actions",
    button: "true",
    size: 5,
    Cell: ({ row }) => {
      const { serial_number } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={`/taximeter/${serial_number}`} />
        </Group>
      );
    },
  },
];
