import { Card, CardBody, Col, Row } from "reactstrap";
import React, { Fragment, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";

import logoSmarteo from "assets/images/logo/smarteo.jpeg";
import logoTaxiora from "assets/images/logo/logo_taxiora.svg";

import useFetchData from "hooks/useFetchDataWithDep";

import Breadcrumbs from "Components/Breadcrumbs";
import Loading from "Components/Loading/Loading";
import NotFound from "Components/NotFound";

import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getRowRes, columnsProd, columnsServ } from "./data";
import StaticTab from "./StaticTab";
import styles from "./estimate.module.css";
import PrintEstiamte from "./PrintEstiamte";
import {
  CancelBtn,
  CreateBillBtn,
  DeliverBtn,
  ReadyBtn,
} from "pages/DeliveryFormPage/DfBtns";
import { Group, Space } from "@mantine/core";

const ReadDeliveryFormPage = () => {
  const { refresh } = useSelector((state) => state.searchLink);

  const { code } = useParams();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const url = `/back/deliveryForm/${code}`;
  const {
    data: estimatesData,
    isLoading,
    isError,
  } = useFetchData(url, refresh);

  let estimateData;
  if (isLoading) return <Loading />;

  if (isError) return <NotFound />;

  if (!isLoading && !isError) {
    estimateData = getRowRes(estimatesData);
  }

  const {
    status,

    client_info: completeName,
    dfNumber: estimateNumber,
    taxi_info,
    createdAt,

    company,
    city,
    zipCode,
    address,

    to_bill,
    to_pickup,
    to_deliver,
    to_cancel,
  } = estimateData;

  const titleEstimate = `Bon de livraison n° ${estimateNumber}`;

  const showProd = estimateData.products.length ? true : false;
  const showService = estimateData.services.length ? true : false;
  const breadCrumbsProps = [
    `Bon de livraison n° ${estimateNumber} | ${status}`,
  ];

  const cancelUrl = `/back/deliveryForm/${code}`;
  const urlReady = `/back/deliveryForm/${code}/ready`;
  const urlDeliver = `/back/deliveryForm/${code}/inDelivery`;
  const createBillUrl = `/back/bill/${code}`;

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Breadcrumbs breadCrumbsProps={breadCrumbsProps} />
          <div ref={componentRef}>
            <Row style={{ marginLeft: "50px", marginTop: "50px" }}>
              <Col className="col-7">
                <div style={{ marginLeft: "20px" }}>
                  <img src={logoTaxiora} className={styles["logo"]} alt="" />
                  <img src={logoSmarteo} className={styles["logo"]} alt="" />
                  {/* <div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      {`${city} ${zipCode}`}
                    </div>
                    <div>{address}</div>
                    <div>{company}</div>
                  </div> */}
                </div>
              </Col>
              <Col className="col-5">
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginBottom: "10px",
                    }}
                  >
                    {titleEstimate}
                  </div>
                  <div>{completeName}</div>
                  <div>{taxi_info}</div>
                  <div>{createdAt}</div>
                </div>
              </Col>
            </Row>

            <div>
              <div style={{ margin: "40px" }}>
                {showProd && (
                  <Fragment>
                    <div
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      Produits
                    </div>
                    <StaticTab
                      newColumns={columnsProd}
                      newRows={estimateData.products}
                    />
                  </Fragment>
                )}
                {showService && (
                  <Fragment>
                    <div style={{ fontSize: "16px", marginTop: "10px" }}>
                      Services
                    </div>

                    <StaticTab
                      newColumns={columnsServ}
                      newRows={estimateData.services}
                    />
                  </Fragment>
                )}
              </div>
            </div>
          </div>

          {/* <div className={styles["print-btn"]}>
            <PrintBtn onClick={handlePrint} />
          </div> */}

          <PrintEstiamte
            componentRef={componentRef}
            estimateNumber={estimateNumber}
          />
          <Space h="md" />

          <Group gap="xs" justify="center">
            <CreateBillBtn url={createBillUrl} disabled={!to_bill} />
            <ReadyBtn url={urlReady} disabled={!to_pickup} />
            <DeliverBtn url={urlDeliver} disabled={!to_deliver} />
            <CancelBtn url={cancelUrl} disabled={!to_cancel} />
          </Group>
        </CardBody>
      </Card>

      <Helmet>
        <title>Bon de livraison: {estimateNumber}</title>
      </Helmet>
    </Fragment>
  );
};

export default ReadDeliveryFormPage;
