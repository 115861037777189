import { z } from "zod";

import { fullName } from "helper/fullName";

import { civilityObj, createdAt, testerObj } from "Constant/objs";

import { Badge, Group } from "@mantine/core";

import ActiveBtn from "Components/MantineDataTableBtns/ActiveBtn";
import EditBtn from "Components/MantineDataTableBtns/EditBtn";
import ReadPageBtn from "Components/MantineDataTableBtns/ReadPageBtn";
import Translate from "Components/Translate";

import { CLIENT, TESTER } from "Constant";

export const API = {
  list: "/back/clients",
  get: "/back/client/",
  patch: "/back/client/",
  create: "/back/client",
  edit: "/back/client/",
};

export const COLUMNS = [
  {
    name: "Full name",
    selector: "name",
  },
  {
    accessorFn: (originalRow) => originalRow.tester,
    id: "tester",
    header: TESTER,
    filterFn: "equals",
    filterVariant: "select",
    mantineFilterSelectProps: {
      data: [
        { value: "true", label: "Testeur" },
        { value: "false", label: "Client" },
      ],
    },

    Cell: ({ row }) => {
      const { tester } = row.original;

      return (
        <Badge color={tester ? "gray" : "green"} size="lg">
          {tester ? (
            <Translate>{TESTER}</Translate>
          ) : (
            <Translate>{CLIENT}</Translate>
          )}
        </Badge>
      );
    },
  },

  { name: "Drivers", selector: "count_drivers" },
  { name: "Taxis", selector: "count_taxis" },
  // {
  //   name: "Active",
  //   cell: (row) => {
  //     return <ActiveBtn patch={API.patch + row.code} state={row.active} />;
  //   },
  //   button: "true",
  // },
  createdAt,

  {
    header: "Actions",
    id: "actions",
    button: "true",
    size: 20,
    Cell: ({ row }) => {
      const { lastname, code, active, firstname } = row.original;
      return (
        <Group gap="xs">
          <ReadPageBtn read={`/client/${code}`} />
          <EditBtn
            read={API.get + code}
            extra={fullName(firstname, lastname)}
          />
          <ActiveBtn patch={API.get + code} state={active} />
        </Group>
      );
    },
  },
];

export const EDIT_ITEMS = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  {
    title: "Company",
    placeholder: "company",
    name: "company",
    inputForm: "SimpleInput",
  },
  {
    title: "Phone",
    placeholder: "phone",
    name: "phone",
    inputForm: "SimpleInput",
    schema: z
      .string()
      .min(1, { message: "This field is required" })
      .transform((val) => val.replace(/\s+/g, "")),
  },
  {
    title: "Registry",
    placeholder: "Registry",
    name: "registry",
    inputForm: "SimpleInput",
  },

  {
    title: "Address",
    placeholder: "address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "city",
    name: "city",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip code",
    placeholder: "zip code",
    name: "zipCode",
    inputForm: "SimpleInput",
  },

  {
    title: "Pro card",
    placeholder: "pro card",
    name: "proCard",
    inputForm: "SimpleInput",
  },
  {
    title: "Pro card got at",
    placeholder: "pro card got at",
    name: "proCardGotAt",
    inputForm: "DateInput",
  },
  {
    title: "Pro card expire at",
    placeholder: "pro card expire at",
    name: "proCardExpireAt",
    inputForm: "DateInput",
  },

  {
    title: "active",
    name: "active",
    inputForm: "CheckBox",
  },
];

export const EDIT_ITEMS_CLIENT = [
  {
    title: "First name",
    placeholder: "First name",
    name: "firstname",
    inputForm: "SimpleInput",
  },
  {
    title: "Last name",
    placeholder: "Last name",
    name: "lastname",
    inputForm: "SimpleInput",
  },
  civilityObj,
  {
    title: "Email",
    placeholder: "email",
    name: "mail",
    inputForm: "SimpleInput",
    schema: z.optional(z.string()),
    required: false,
  },
  {
    title: "Company",
    placeholder: "company",
    name: "company",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Phone",
    placeholder: "phone",
    name: "phone",
    inputForm: "SimpleInput",
    schema: z.string().transform((val) => val.replace(/\s+/g, "")),
  },
  {
    title: "Registry",
    placeholder: "Registry",
    name: "registry",
    inputForm: "SimpleInput",
  },

  {
    title: "Address",
    placeholder: "address",
    name: "address",
    inputForm: "SimpleInput",
  },
  {
    title: "City",
    placeholder: "city",
    name: "city",
    inputForm: "SimpleInput",
  },
  {
    title: "Zip code",
    placeholder: "zip code",
    name: "zipCode",
    inputForm: "SimpleInput",
  },

  {
    title: "Pro card",
    placeholder: "pro card",
    name: "proCard",
    inputForm: "SimpleInput",
    required: false,
  },
  {
    title: "Pro card got at",
    placeholder: "pro card got at",
    name: "proCardGotAt",
    inputForm: "DateInput",
    required: false,
  },
  {
    title: "Pro card expire at",
    placeholder: "pro card expire at",
    name: "proCardExpireAt",
    inputForm: "DateInput",
    required: false,
  },
  testerObj,
];
