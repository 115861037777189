import {
  Accordion,
  Avatar,
  Center,
  Group,
  Paper,
  Space,
  Text,
} from "@mantine/core";
import React, { Fragment } from "react";
import { IconTool, IconXboxX } from "@tabler/icons-react";

import SimpleTable from "ComponentsMantine/SimpleTable";

import { EQUIPEMENT, UPDATE_EQUIPMENT } from "Constant";

import Translate from "Components/Translate";

import { isEmpty } from "./isEmpty";
import UpdateBtn from "./UpdateBtn";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import HandleResult from "helper/HandleResult";

const EquipmentsComp = ({ data, taxiData }) => {
  if (isEmpty(data)) return isEmpty(data);

  const {
    box,
    luminous,
    printer,
    sunshield,
    sos_button,
    stylus,
    relay_switch,
    sensor,
    obd_adapter,
    bracket,
  } = data;

  const requiredKeys = [
    { title: "Box", serial: box?.serial_number_box },
    { title: "Luminous", serial: luminous?.serial_number_luminous },
    { title: "Printer", serial: printer?.serial_number_printer },
    { title: "Sunshield", serial: sunshield?.serial_number_sunshield },
    { title: "SOS Button", serial: sos_button?.serial_number_sos_button },
    { title: "Stylus", serial: stylus?.serial_number_stylus },
    { title: "Relay Switch", serial: relay_switch?.serial_number_relay_switch },
    { title: "Sensor", serial: sensor?.serial_number_sensor },
    { title: "OBD Adapter", serial: obd_adapter?.serial_number_obd_adapter },
    { title: "Bracket", serial: bracket?.serial_number_bracket },
  ];

  const taximeterCode = taxiData?.taximeter?.code_taximeter ?? null;
  const urlUpdate = `/back/taximeter/${taximeterCode}/components`;

  const disabled = taxiData?.taximeter?.state === "free" ? false : true;

  return (
    <Fragment>
      <UpdateBtn
        title={UPDATE_EQUIPMENT}
        path={urlUpdate}
        disabled={disabled}
      />
      <Space h="md" />
      <SimpleTable requiredKeys={requiredKeys} />

      {/* <DataTable value={requiredKeys} size="small">
        <Column field="title" header="Equipment" />
        <Column
          field="serial"
          header="Serial"
          body={({ serial }) => {
            if (!serial) return <IconXboxX color="red" />;
          }}
        />
      </DataTable> */}
    </Fragment>
  );
};

export default EquipmentsComp;
