import EmptyData from "Components/EmptyData";
import Loading from "Components/Loading/Loading";
import useFetchData from "hooks/useFetchDataWithDep";
import React from "react";
import { useParams } from "react-router-dom";

const SheetOfTaximeterPage = () => {
  const { code } = useParams();

  const url = `/back/taximeter/${code}/datasheet`;
  const { data, isLoading, isError } = useFetchData(url);

  const taximeterSheet = getRowsFromRes(data);

  if (isLoading) return <Loading />;
  if (isError) return <EmptyData />;
  return <pre>{JSON.stringify(taximeterSheet, null, 2)}</pre>;
};

export default SheetOfTaximeterPage;

const getRowsFromRes = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return [];
  }
};
